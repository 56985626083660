/** @jsx jsx */
import { useState, useEffect } from 'react'
import { jsx, Grid, Box, Card } from 'theme-ui'
import Layout from '../../components/layout'
import Hero from '../../components/hero'
import { responsive } from '../../utils/helpers'
import ResultsTable from '../../components/results'
import LeagueTable from '../../components/leagueTable'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { getFixturesByMonth } from '../../api_aws/opta'
import Accordian from '../../components/accordion'
import NextMatch from '../../components/nextMatch'
import MetaData from '../../components/metadata'

const FixurePage = props => {
  const [results, setResults] = useState([])

  useEffect(
    () => {
      ;(async () => {
        setResults([])
        const opta = await getFixturesByMonth()
        if (opta.success) setResults(opta.data)
      })()
    },
    [
      // setResults,
    ]
  )

  console.log(results)

  return (
    <Layout {...props}>
      <MetaData title="Fixtures" />

      <Hero
        data={[
          {
            type: 'Custom',
            children: (
              <NextMatch
                title="Next Match"
                variant="large"
                // highlights
                tickets
                // travel
                live
              />
            ),
          },
        ]}
      />

      <Wrapper>
        <Inner>
          <Grid columns={responsive(1, '65% 1fr')} gap={10} pt={11}>
            <Box>
              {results.length < 1 && <p sx={{ py: 5 }}>No Results</p>}

              {results.map((x, i) => {
                return (
                  <Box mb={4} key={i}>
                    <ResultsTable
                      heading={x.label}
                      data={x.fixtures.map(x => {
                        return {
                          competition: x.league,
                          heading: `${x.venue}, ${x.time} Kick off`,
                          date: x.date,
                          fixtures: [
                            {
                              name: x.home.shortName
                                ? x.home.shortName
                                : x.home.name,
                              crest: x.home.crest,
                              score: x.home.score,
                            },
                            {
                              name: x.away.shortName
                                ? x.away.shortName
                                : x.away.name,
                              crest: x.away.crest,
                              score: x.away.score,
                            },
                          ],
                        }
                      })}
                    />
                  </Box>
                )
              })}
            </Box>
            <Box mb={5}>
              <Card variant="slim">
                <Accordian heading="League table" open noBorder>
                  <LeagueTable variant="small" />
                </Accordian>
              </Card>
            </Box>
          </Grid>
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export default FixurePage
